var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true }
        }
      ],
      staticClass: "sessionview fill vfillpanel"
    },
    [
      _vm.error
        ? _c(
            "el-alert",
            { attrs: { type: "error" } },
            [
              _vm._v(" " + _vm._s(_vm.error.message) + " "),
              _c("el-button", { on: { click: _vm.loadMessage } }, [
                _vm._v("Retry")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.error ? _c("div", { staticClass: "hfillpanel fill" }) : _vm._e(),
      _vm.sessionSummary
        ? _c(
            "div",
            { staticClass: "hfillpanel fill" },
            [
              _vm.session && _vm.session.error
                ? _c(
                    "el-alert",
                    {
                      attrs: {
                        type: "warning",
                        "show-icon": "",
                        title: "This session terminated abnormally"
                      }
                    },
                    [_vm._v(_vm._s(_vm.session.error))]
                  )
                : _vm._e(),
              _c(
                "el-tabs",
                {
                  staticClass: "fill",
                  staticStyle: { height: "100%", width: "100%" },
                  attrs: { value: "log", type: "border-card" }
                },
                [
                  _c(
                    "el-tab-pane",
                    { staticClass: "hfillpanel", attrs: { name: "log" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-notebook-2" }),
                        _vm._v(" Log ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "toolbar" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.download }
                            },
                            [_vm._v("Open")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.log,
                              expression: "log"
                            }
                          ],
                          staticClass: "vfillpanel fill"
                        },
                        [
                          _c("textview", {
                            staticClass: "fill",
                            attrs: { text: _vm.log }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.sessionSummary
        ? _c("div", { staticClass: "fill nodetails centrecontents" }, [
            _c("div", [_vm._v("No session selected")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }