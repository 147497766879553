var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.loading,
          expression: "loading",
          modifiers: { body: true }
        }
      ],
      staticClass: "messageview fill vfillpanel"
    },
    [
      _vm.error
        ? _c(
            "el-alert",
            { attrs: { type: "error" } },
            [
              _vm._v(" " + _vm._s(_vm.error.message) + " "),
              _c("el-button", { on: { click: _vm.loadMessage } }, [
                _vm._v("Retry")
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.messageSummary
        ? _c(
            "div",
            { staticClass: "hfillpanel fill" },
            [
              _c(
                "div",
                { staticClass: "toolbar" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-download" },
                      on: { click: _vm.download }
                    },
                    [_vm._v("Download")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pad" },
                [
                  _vm._l(_vm.warnings, function(warning) {
                    return _c("el-alert", {
                      key: warning.details,
                      attrs: {
                        title: "Warning: " + warning.details,
                        type: "warning",
                        "show-icon": ""
                      }
                    })
                  }),
                  _c("table", { staticClass: "messageviewheader" }, [
                    _c("tr", [
                      _c("td", [_vm._v("From:")]),
                      _c("td", [
                        _vm.message
                          ? _c("span", [_vm._v(_vm._s(_vm.message.from))])
                          : _vm._e()
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("To:")]),
                      _c("td", [
                        _vm.message
                          ? _c("span", [_vm._v(_vm._s(_vm.message.to))])
                          : _vm._e()
                      ])
                    ]),
                    _vm.message && _vm.message.cc
                      ? _c("tr", [
                          _c("td", [_vm._v("Cc:")]),
                          _c("td", [_vm._v(_vm._s(_vm.message.cc))])
                        ])
                      : _vm._e(),
                    _vm.message && _vm.message.bcc
                      ? _c("tr", [
                          _c("td", [_vm._v("Bcc:")]),
                          _c("td", [_vm._v(_vm._s(_vm.message.bcc))])
                        ])
                      : _vm._e(),
                    _c("tr", [
                      _c("td", [_vm._v("Subject:")]),
                      _c("td", [
                        _vm.message
                          ? _c("span", [_vm._v(_vm._s(_vm.message.subject))])
                          : _vm._e()
                      ])
                    ]),
                    _c("tr", [
                      _c("td", [_vm._v("Secure:")]),
                      _c("td", [
                        _vm.message
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.message.secureConnection))
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]),
                  _vm.message && _vm.message.relayError
                    ? _c(
                        "div",
                        [
                          _c("el-alert", { attrs: { type: "error" } }, [
                            _vm._v(
                              "Message relay error: " +
                                _vm._s(_vm.message.relayError)
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.message && _vm.message.mimeParseError
                    ? [
                        _c("el-alert", { attrs: { type: "error" } }, [
                          _vm._v(
                            "Message parse error: " +
                              _vm._s(_vm.message.mimeParseError)
                          )
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "fill",
                  staticStyle: { height: "100%", width: "100%" },
                  attrs: { value: "view", type: "border-card" }
                },
                [
                  _c(
                    "el-tab-pane",
                    { staticClass: "hfillpanel", attrs: { name: "view" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-view" }),
                        _vm._v(" View ")
                      ]),
                      _c("messageviewattachments", {
                        attrs: { message: _vm.message }
                      }),
                      _c("messageview-html", {
                        staticClass: "fill",
                        attrs: { message: _vm.message }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { staticClass: "hfillpanel", attrs: { name: "headers" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-notebook-2" }),
                        _vm._v(" Headers ")
                      ]),
                      _c("headers", {
                        staticClass: "fill",
                        attrs: { headers: _vm.headers }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { staticClass: "hfillpanel", attrs: { name: "parts" } },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-document-copy" }),
                        _vm._v(" Parts ")
                      ]),
                      _vm.message
                        ? _c("el-tree", {
                            ref: "partstree",
                            attrs: {
                              data: _vm.message.parts,
                              props: {
                                label: "name",
                                children: "childParts",
                                disabled: false,
                                isLeaf: _vm.isLeaf
                              },
                              "highlight-current": "",
                              "empty-message": "No parts",
                              accordion: "",
                              "node-key": "id",
                              "default-expanded-keys": ["0"]
                            },
                            on: { "node-click": _vm.onPartSelection },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var node = ref.node
                                    var data = ref.data
                                    return _c(
                                      "span",
                                      { staticClass: "custom-tree-node" },
                                      [
                                        _c("i", {
                                          class: {
                                            "el-icon-document-copy":
                                              data.childParts.length,
                                            "el-icon-document":
                                              data.childParts.length == 0 &&
                                              !data.isAttachment,
                                            "el-icon-paperclip":
                                              data.childParts.length == 0 &&
                                              data.isAttachment
                                          }
                                        }),
                                        _vm._v(" " + _vm._s(node.label) + " ")
                                      ]
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              1581920248
                            )
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.selectedPart,
                              expression: "selectedPart"
                            }
                          ],
                          staticClass: "fill vfillpanel"
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              staticClass: "fill hfillpanel",
                              attrs: { value: "headers", type: "border-card" }
                            },
                            [
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "fill vfillpanel",
                                  attrs: { label: "Headers", name: "headers" }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { slot: "label" }, slot: "label" },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-notebook-2"
                                      }),
                                      _vm._v(" Headers ")
                                    ]
                                  ),
                                  _c("headers", {
                                    staticClass: "fill",
                                    attrs: { headers: _vm.selectedPartHeaders }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "fill vfillpanel",
                                  attrs: { label: "Source", name: "source" }
                                },
                                [
                                  _c("messagepartsource", {
                                    staticClass: "fill",
                                    attrs: {
                                      messageEntitySummary: _vm.selectedPart,
                                      type: "source"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-tab-pane",
                                {
                                  staticClass: "fill vfillpanel",
                                  attrs: { label: "Raw", name: "raw" }
                                },
                                [
                                  _c("messagepartsource", {
                                    staticClass: "fill",
                                    attrs: {
                                      messageEntitySummary: _vm.selectedPart,
                                      type: "raw"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.selectedPart
                        ? _c("div", { staticClass: "fill vfillpanel" }, [
                            _c(
                              "div",
                              { staticClass: "fill nodetails centrecontents" },
                              [_c("div", [_vm._v("No part selected.")])]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    {
                      staticClass: "fill vfillpanel",
                      attrs: { label: "Source", name: "source" }
                    },
                    [
                      _c("messagesource", {
                        staticClass: "fill",
                        attrs: { message: _vm.message, type: "source" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.messageSummary
        ? _c("div", { staticClass: "fill nodetails centrecontents" }, [
            _c("div", [_vm._v("No message selected.")])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }